/* ChatRoom.css */
.chatroom-container {
  display: flex;
  justify-content: center;
  background-color: #202123;
  height: 100vh;
  align-items: center;
  color: white;
}

.video-container {
  flex: 0.7;
  padding: 20px;
}

.title {
  position: absolute;
  display: flex;
  justify-content: center;
  font-weight: 500;
}

iframe {
  width: 100%;
  height: 80vh;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.chat-history {
  max-width: 100%;
  max-height: 80%;
  display: flex;
  flex-direction: column-reverse;
  background-color: #444654;
  border-radius: 10px;
  padding: 10px;
  padding-bottom: 0px;
  height: 75vh;
  overflow-y: auto;
}

.chat-container {
  flex: 1;
  max-width: 20%;
  max-height: 80%;
}

/* total width */
::-webkit-scrollbar {
  background-color: #444654;
  width: 16px;
}

/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
  background-color: #444654;
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
  background-color: #343540;
  border-radius: 16px;
  border: 4px solid #444654;
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
  display: none;
}

form {
  max-height: 20%;
  display: flex;
  margin-top: 5px;
  border-top: 2px solid #282c34;
  padding-top: 10px;
}

input,
button {
  padding: 10px;
  font-size: 12px;
}

input {
  flex: 1;
  margin-right: 10px;
  border-radius: 5px;
  border: none;
  background-color: #282c34;
  color: white;
}

button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #b3fcff;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.comment-username {
  color: rgb(205, 222, 227);
  font-weight: 700;
}

.comment-timestamp {
  color: rgb(56, 183, 228);
}

li:last-child {
  margin-bottom: 0;
}

.timestamp {
  display: none;
}

.top-right-container {
  position: absolute;
  top: 10px;
  right: 60px;
  display: flex;
  align-items: center;
  margin-right: 0px;
}

.top-right-container button {
  padding: 5px 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
}

.comment-bubble {
  position: relative;
  max-width: 90%;
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 15px;
  background-color: #343540;
  color: rgb(255, 255, 255);
  word-break: break-all;
  font-size: 12px;
}

.delete-button {
  position: absolute;
  top: 5px;
  right: 5px;
  background: none;
  border: none;
  cursor: pointer;
  color: #999;
  transition: color 0.3s ease;
  opacity: 0%;
}

.delete-button:hover {
  color: #ff0000;
  opacity: 80%;
}
