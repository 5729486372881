.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #282c34;
    color: white;
  }
  
  h1 {
    margin-bottom: 50px;
  }
  
  .form {
    display: flex;
    flex-direction: column;
    width: 80%;
    max-width: 500px;
  }
  
  .input {
    margin-bottom: 10px;
    padding: 10px;
    font-size: 16px;
    border-radius: 5px;
    border: none;
  }
  
  .button {
    padding: 10px;
    font-size: 16px;
    background-color: #61dafb;
    color: black;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .button:hover {
    background-color: #b3fcff;
  }
  
  .error {
    color: red;
    margin-top: 10px;
  }
  
  /* For devices smaller than 600px */
  @media (max-width: 600px) {
    .form {
      width: 90%;
    }
  }
  